

























































import Vue from "vue";
import qs from "qs";
import LazyImg from "@/components/LazyImg.vue";
import Avatar from "@/components/Avatar.vue";
import errorImg from "@/assets/img/error.png";
import { joinUrlQueryString } from "@/utils/util";
import ssoMixin from "@/mixins/sso";
import feedMixin from "@/mixins/feed";

export default Vue.extend({
  name: "waterfall-item",
  mixins: [ssoMixin, feedMixin],
  filters: {
    formatRatio(info) {
      return `padding-bottom: ${(
        ((info.height || 9) / (info.width || 16)) *
        100
      ).toFixed(2)}%`;
    },
  },
  components: {
    LazyImg,
    Avatar,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    index: {
      type: Number,
      default: 1,
    },
    from: {
      type: String,
      default: "",
    },
    page: {
      type: String,
      default: "home",
    },
    kind: {
      type: String,
      default: "",
    },
    pid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      errorImg,
      reporting: false,
    };
  },
  computed: {
    curUser() {
      return this.$store.state.user.curUser;
    },
    hasLogin() {
      return this.curUser.userId !== "0";
    },
    isVip() {
      return this.curUser.vipData && this.curUser.vipData.isVip;
    },
    isPcClient() {
      if (process.client) {
        return !!window.native;
      }
      return this.$store.state.isPCNative;
    },
    queryStr() {
      const queryObj = this.$route.query;
      const routeName = this.$route.name;
      const queryStr = joinUrlQueryString(queryObj, routeName);
      return queryStr;
    },
    payParams() {
      return this.$store.state.vip.payParams;
    },
    detailUrl() {
      if (this.info.partner) {
        const infoStr = qs.stringify({
          partner: this.info.partner,
          tid: this.info.tid,
          rid: this.info.id,
        });
        return `/blank/third/?${infoStr}&${this.queryStr}`;
      }
      return `/detail/${this.info.id}?d_from=${this.page}&${this.queryStr}`;
    },
  },
  mounted() {},
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    reportStat(clickid: string) {
      const newInfo = { ...this.info, title: this.info.res_name };
      this.genFeedStatData(
        [newInfo],
        this.page,
        this.kind,
        clickid,
        this.index
      );
    },
    reportPayStat() {
      if (this.info.payment === 2 && !this.reporting) {
        this.reporting = true;
        this.stat("xiaozhan_vip_card_pay_show", {
          cardID: this.info.id,
          referfrom: "v_wap_xiaozhan_ggong_card_button",
          aidfrom: this.payParams.aidfrom,
          zone_name: encodeURIComponent(this.kind),
        });

        setTimeout(() => {
          this.reporting = false;
        }, 800);
      }
    },
    async checkDetail(e: Event) {
      if (this.info.partner) {
        e.preventDefault();

        const infoStr = qs.stringify({
          partner: this.info.partner,
          tid: this.info.tid,
          rid: this.info.id,
        });

        window.open(
          `/blank/third/?${infoStr}&${this.queryStr}`,
          "_blank",
          "noopener,noreferrer"
        );
        // this.jumpThirdParyUrl(this.info);
      }
    },
    reportActionStat() {
      if (this.info.payment === 2) {
        this.$store.commit("vip/SET_PAY_PARAMS", {
          referfrom: "v_wap_xiaozhan_ggong_card_button",
          cardID: this.info.id,
          zone_name: encodeURIComponent(this.kind),
          need_vip: this.info.payment === 2 ? 1 : 0,
        });

        this.stat("xiaozhan_vip_card_pay_click", {
          cardID: this.info.id,
          referfrom: "v_wap_xiaozhan_ggong_card_button",
          aidfrom: this.payParams.aidfrom,
          zone_name: encodeURIComponent(this.kind),
        });
      }

      const info = this.info;

      this.stat("xiaozhan_download_btn_click", {
        from: this.from,
        position: "card",
        is_login: this.hasLogin,
        upUid: info.user_info ? info.user_info.user_id : info.user_id || "",
        cardID: info.id,
        need_vip: info.payment === 2 ? 1 : 0,
        zone_name: encodeURIComponent(this.kind),
      });
    },
    jumping(e: Event) {
      this.reportActionStat();
    },
    download(e: Event) {
      this.reportActionStat();
      this.handleDownload(this.info, this.from, "card", [], {
        zone_name: encodeURIComponent(this.kind),
      });
    },
    async collect() {
      if (!this.hasLogin) {
        this.popLogin();
        return;
      }

      const info = this.info;
      if (this.info.is_favor) {
        try {
          const result = await this.$store.dispatch("post/cancelFavoriteFeed", {
            rid: info.id,
          });
          // this.$message({
          //   type: "success",
          //   message: "取消收藏成功",
          // });
        } catch (e) {
          this.$message({
            type: "warning",
            message: "取消收藏失败",
          });
        }

        this.stat("xiaozhan_cancel_collect_btn_click", {
          from: this.from,
          position: "card",
          upUid: info.user_info ? info.user_info.user_id : info.user_id || "",
          cardID: info.id,
          need_vip: info.payment === 2 ? 1 : 0,
          zone_name: encodeURIComponent(this.kind),
        });
        return;
      }

      this.stat("xiaozhan_collect_btn_click", {
        from: this.from,
        position: "card",
        is_collect_all_file: 1,
        is_login: this.hasLogin ? 1 : 0,
        upUid: info.user_info ? info.user_info.user_id : info.user_id || "",
        cardID: info.id,
        zone_name: encodeURIComponent(this.kind),
      });

      this.handleSave(this.info, null, [], this.page, true, this.from, {
        pid: this.pid,
        zone_name: encodeURIComponent(this.kind),
      });
    },
  },
});
