

























import Vue from "vue";
import WaterfallItem from "@/components/WaterfallItem.vue";
import { findMinNumberIndex } from "@/utils/util";
import feedMixin from "@/mixins/feed";
import { IFeedItem } from "@/types/post";

export default Vue.extend({
  name: "waterfall",
  mixins: [feedMixin],
  components: {
    WaterfallItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    rowGutter: {
      type: Number,
      default: 24,
    },
    columnGutter: {
      type: Number,
      default: 16,
    },
    page: {
      type: String,
      default: "",
    },
    from: {
      type: String,
      default: "",
    },
    extra: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      resizeObserver: null,
      requestAnimationId: null,
      column: 5,
      columnWidth: 190,
      lastReportIndex: 0,
      shownCardDataMap: {},
      shownCardData: [],
      timer: null,
      visible: "hidden",
    };
  },
  computed: {
    listData() {
      const column = this.column;
      const columnHeight = new Array(column).fill(0);
      const columnData = new Array(column).fill([]);
      for (let item of this.data) {
        const minIndex = findMinNumberIndex(columnHeight);
        const { height = 0, width = 0 } = item;
        const ratio = (height || 9) / (width || 16);
        const realHeight = ratio * this.columnWidth;
        columnData[minIndex] = [...columnData[minIndex], item];
        columnHeight[minIndex] += realHeight + this.rowGutter;
      }

      return columnData;
    },
  },
  watch: {
    shownCardData(newVal, oldVal) {
      this.timer = setTimeout(() => {
        this.reportShowStat();
      }, 1000);
    },
  },
  beforeMount() {
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver((entries) => {
        // avoid ResizeObserver loop limit exceeded warning
        this.requestAnimationId = window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          for (let entry of entries) {
            if (entry.target.nodeName.toLowerCase() === "body") {
              const width = entry.contentRect.width;
              this.handleResize(width);
            }
          }
        });
      });

      const bodyElement = document.querySelector("body");
      if (bodyElement) {
        this.resizeObserver.observe(bodyElement);
      }
    }

    // TODO: SSR后显示元素，此法是否会被SE误判为作弊有待验证
    this.visible = "visible";
  },
  mounted() {},
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }

    if (this.requestAnimationId && window.cancelAnimationFrame) {
      window.cancelAnimationFrame(this.requestAnimationId);
    }

    this.shownCardDataMap = {};
    this.shownCardData = [];
    this.lastReportIndex = 0;
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
  methods: {
    stat(action: string, data: any) {
      this.$stat("xiaozhan", action, data);
    },
    handleResize(width: number) {
      let column = 5;
      let realWidth = 998;

      if (width >= 998) {
        column = 5;
        realWidth = 998;
      } else if (width < 998 && width >= 750) {
        column = 4;
        realWidth = width;
      } else if (width < 750 && width > 540) {
        column = 3;
        realWidth = width;
      } else {
        column = 2;
        realWidth = width;
      }

      this.columnWidth =
        (realWidth - (column - 1) * this.columnGutter) / column;
      this.column = column;
    },
    handleCardVisible(item: IFeedItem, index: number, visible: boolean) {
      if (visible) {
        const rawDataMap = { ...this.shownCardDataMap };
        const rawData = [...this.shownCardData];
        if (!rawDataMap[item.id]) {
          rawDataMap[item.id] = item;
          rawData.push(item);
        }

        this.shownCardDataMap = rawDataMap;
        this.shownCardData = rawData;
      }
    },
    reportShowStat() {
      const len = this.shownCardData.length;
      const lastIndex = this.lastReportIndex;
      const unit = 4;

      for (let i = lastIndex; i < len; i = i + unit) {
        const chunk = this.shownCardData
          .slice(i, i + unit)
          .map((c: IFeedItem) => ({ ...c, title: c.res_name }));

        this.genFeedStatData(
          chunk,
          this.page,
          this.extra.zone_name || "",
          "",
          lastIndex
        );
      }

      this.lastReportIndex = len;
    },
  },
});
